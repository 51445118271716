import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';

import {IdentifierContextProvider} from './identifier-context';
import {Provider} from "react-redux";
import store from "./store/store";
import {SnackbarProvider} from 'notistack';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <IdentifierContextProvider>
    <Provider store={store}>
      <SnackbarProvider maxSnack={3}>
        <App/>
      </SnackbarProvider>
    </Provider>
  </IdentifierContextProvider>
);
