/**
 * (c) Copyright Reserved EVRYTHNG Limited 2021. All rights reserved.
 * Use of this material is subject to license.
 * Copying and unauthorised use of this material strictly prohibited.
 */
/* eslint-disable no-undef */

export const SGLNs = [
  {"Factory A": "urn:epc:id:sgln:4012345.00005.0"},
  {"Factory B": "urn:epc:id:sgln:0012345.11111.400"},
  {"Warehouse A": "urn:epc:id:sgln:9529999.99999.0"},
  {"Warehouse B": "urn:epc:id:sgln:9524567.98765.0"},
  {"Store A": "urn:epc:id:sgln:9524141.00300.0"},
  {"Store B": "urn:epc:id:sgln:0614141.07346.1234"},
]

export const bizSteps = [
  {"commissioning": epcis2.cbv.bizSteps.commissioning},
  {"receiving": epcis2.cbv.bizSteps.receiving},
  {"shipping": epcis2.cbv.bizSteps.shipping},
]

export const zebraID = 'zebraID:9C1214F7123F5642AA5705313F4FB874';
