/**
 *
 * @param {{number, number}} value - {width, height}
 * @returns {{type: string, value: *}}
 */
export const setScreenSize = value => ({
  type: 'SET_SCREEN_SIZE',
  value,
});

/**
 *
 * @param {boolean} value - true if it is on mobile - false otherwise
 * @returns {{type: string, value: *}}
 */
export const setIsMobile = value => ({
  type: 'SET_IS_MOBILE',
  value,
});

/**
 *
 * @param {string} value
 * @returns {{type: string, value: *}}
 */
export const setStoreBizStep = value => ({
    type: 'SET_BIZSTEP',
    value,
});


/**
 *
 * @param {string} value
 * @returns {{type: string, value: *}}
 */
export const setStoreSgln = value => ({
    type: 'SET_SGLN',
    value,
});

export const setStoreDestination = value => ({
  type: 'SET_DESTINATION',
  value,
});
