import { parseBarcode } from "gs1-barcode-parser-mod";
import { useEffect, useContext, useState } from "react";

import IdentifierContext from "./identifier-context.js";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select  from '@mui/material/Select';
import {bizSteps, SGLNs} from "./utils/constants";
import {captureEvent} from "./services/epcis";
import { useDispatch } from 'react-redux';
import {setStoreSgln, setStoreDestination, setStoreBizStep} from './store/action'
import { useSnackbar } from 'notistack';

const CustomSelect = ({value, onChange, id, label, options}) => {
  return (
    <FormControl fullWidth>
      <InputLabel id={`${id}-input`}>{label}</InputLabel>
      <Select
        labelId={`${id}-input`}
        id={id}
        value={value}
        label={label}
        onChange={onChange}
      >
        {
          options.map((k) => {
            return <MenuItem key={Object.values(k)[0]} value={Object.values(k)[0]}>{Object.keys(k)[0]}</MenuItem>;
          })
        }
      </Select>
    </FormControl>
  )
}

function App() {
  const identifierContext = useContext(IdentifierContext);
  const aiToConsider = ["01", "21"];
  const [sgln, setSgln] = useState(Object.values(SGLNs[0])[0]);
  const [bizStep, setBizStep] = useState(Object.values(bizSteps[0])[0]);
  const [destination, setDestination] = useState(Object.values(SGLNs[0])[0]);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    let tmpIdentifier = "";
    document.addEventListener('keyup', (event) => {
        if (tmpIdentifier.length === 0) {
          setTimeout(() => {
            console.log(`Scan detected: ${tmpIdentifier}`);
            let identifier;
            if (tmpIdentifier.slice(0, 4) === "http") {
              // scanner reads different characters instead of ':' so we need to replace it
              const indexOfEndReplacement = tmpIdentifier.indexOf(";");
              identifier = "https:" + tmpIdentifier.slice(indexOfEndReplacement+1);
            }
            else {
              identifier = "https://dlnkd.tn.gg";
              let parsedBarcode = parseBarcode(tmpIdentifier);
              for (const item of parsedBarcode.parsedCodeItems) {
                if (aiToConsider.includes(item.ai)) {
                  identifier = identifier + "/" + item.ai + "/" + item.data;
                }
              }
            }
            identifierContext.setIdentifier(identifier);
            captureEvent(identifier);
            enqueueSnackbar('Event captured');
            tmpIdentifier = "";
          }, 500);
        }
        tmpIdentifier = tmpIdentifier + event.key;
    }, false);
  // eslint-disable-next-line
  }, []);

  return (
    <div>
      <div style={{marginTop: '24px'}}/>
      <CustomSelect id={'sgln-select'} label={'SGLN'} value={sgln} onChange={(event) => {
        dispatch(setStoreSgln(event.target.value))
        setSgln(event.target.value)
      }} options={SGLNs}/>
      <div style={{marginTop: '24px'}}/>
      <CustomSelect id={'bizstep-select'} label={'BizStep'} value={bizStep} onChange={(event) => {
        dispatch(setStoreBizStep(event.target.value))
        setBizStep(event.target.value)
      }} options={bizSteps}/>
      {
        bizStep === 'shipping' && (
          <div style={{marginTop: '24px'}}>
            <CustomSelect id={'destination-select'} label={'Destination'} value={destination} onChange={(event) => {
              dispatch(setStoreDestination(event.target.value))
              setDestination(event.target.value)
            }} options={SGLNs}/>
          </div>
        )
      }
    </div>
  );
}

export default App;
