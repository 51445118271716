/**
 * (c) Copyright Reserved EVRYTHNG Limited 2021. All rights reserved.
 * Use of this material is subject to license.
 * Copying and unauthorised use of this material strictly prohibited.
 */

/* eslint-disable no-undef */

import {zebraID} from "../utils/constants";
import store from "../store/store";

export const captureEvent = (epc) => {

  const {bizStep, destination} = store.getState();

  const date = new Date();
  const dateISO = date.toISOString();

  epcis2.setup({
    apiUrl: 'https://api.eu.evrythng.io/v2/epcis/',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': process.env.REACT_APP_OPERATOR_API_KEY,
    }
  });

  const context = {"evt": "https://ns.example.com/evt/"};

  // wrap Zebra scan into EPCIS document
  const object = {
    "type": "ObjectEvent",
    "action": epcis2.cbv.actionTypes.observe,
    "bizStep": bizStep,
    "eventTime": dateISO,
    "eventTimeZoneOffset": "+02:00",
    "readPoint": {"id": zebraID},
    "epcList": [epc],
    "evt:project": "VdaQYdpkeXdfrVbAxheaSHhp"
  }

  if (bizStep === epcis2.cbv.bizSteps.shipping) {
    object.destinationList = [
      { "type": "location", "destination": destination }
    ]
  }

  const epcisEvent = epcis2.objectToEvent(object);
  epcisEvent.generateHashID(context, true);
  const documentObject = {
    "@context": [context],
    "type": "EPCISDocument",
    "schemaVersion": "2.0",
    "creationDate": dateISO,
    "epcisBody": {
      "eventList": [epcisEvent]
    }
  };
  const epcisDocument = new epcis2.EPCISDocument(documentObject);
  console.log(JSON.stringify(epcisDocument.toObject()))
  epcis2.capture(epcisDocument)
}
